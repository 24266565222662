const binding = { modules: {}, dataActions: {} };

    (binding.modules['adventureworks'] = {
        c: () => require('@msdyn365-commerce-theme/adventureworks-theme-kit/dist/lib/modules/adventureworks/adventureworks'),
        $type: 'themeModule',
        da: [],
        definitionExtensions: ['active-image','b2b-requests-status','business-organization-list','buybox','cart-icon','cart','content-block','header','image-list','navigation-menu','order-template-list','promo-banner','quickview','ratings-histogram','reviews-list','search-result-container-v2','search-result-container','search','sign-in','store-selector','text-block','write-review'],
        iNM: true,
        ns: '@msdyn365-commerce-theme',
        n: 'adventureworks',
        p: 'adventureworks-theme-kit',
        
        pdp: '',
        
        themeSettings: 'adventureworks.theme.settings.json',
        md: 'node_modules/@msdyn365-commerce-theme/adventureworks-theme-kit/dist/lib/modules/adventureworks'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };